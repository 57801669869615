/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Layout, Spin, Row } from 'antd';
import { useNotif } from '@hooks/use-notif';
import { useHistory } from 'react-router-dom';
import { useAuth } from './auth-context';

interface AppContextState {
  selectedBranchName: string;
  selectedBranchId: string;
  isLoading: boolean;
  adminRole?: string;
  selectedKey?: string;
  selectedTenantId: string;
  firstLoad: boolean;
}

export interface AppContextValue {
  selectedBranchName?: string;
  selectedBranchId?: string;
  selectBranch: (branchId: string, branchName: string) => void;
  isLoading: boolean;
  adminRole: string;
  selectedTenantId: string;
  selectTenant: (tenantId: string) => void;
}

const Spinner = () => (
  <Layout style={{ placeContent: 'center', height: '100vh' }}>
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  </Layout>
);

const AppContext = React.createContext<AppContextValue>({} as never);

export default function AppContextProvider({ children }) {
  const { identity } = useAuth();
  const history = useHistory();

  const [
    { isLoading, selectedBranchId, adminRole, selectedBranchName, selectedTenantId, firstLoad },
    updateState,
  ] = useState<AppContextState>({
    isLoading: false,
    selectedBranchId: localStorage.getItem('selectedBranchId'),
    selectedBranchName: localStorage.getItem('selectedBranchName'),
    adminRole: null,
    selectedKey: null,
    selectedTenantId: localStorage.getItem('tenantId'),
    firstLoad: true,
  });

  const { addError } = useNotif();

  const setLoading = (state: boolean) => {
    updateState((prevState) => ({
      ...prevState,
      isLoading: state,
    }));
  };

  const selectBranch = (branchId: string, branchName: string) => {
    try {
      if (branchId && branchName) {
        localStorage.setItem('selectedBranchName', branchName);
        localStorage.setItem('selectedBranchId', branchId);
      } else {
        localStorage.removeItem('selectedBranchId');
        localStorage.removeItem('selectedBranchName');
      }
      setLoading(true);

      updateState((prevState) => ({
        ...prevState,
        selectedBranchId: branchId,
        selectedBranchName: branchName,
      }));
    } catch (error) {
      addError(error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const selectTenant = (tenantId: string) => {
    try {
      if (tenantId) {
        localStorage.setItem('tenantId', tenantId);
      }
      setLoading(true);

      updateState((prevState) => ({
        ...prevState,
        selectedTenantId: tenantId,
      }));
    } catch (error) {
      addError(error);
      throw new Error(error);
    } finally {
      setLoading(false);
      if (!firstLoad) {
        history.push(`/tenant/ecommerce/dashboard`);
      }
      updateState((prevState) => ({
        ...prevState,
        firstLoad: false,
      }));
    }
  };

  // const setAdminRole = (role: string) => {
  //   try {
  //     updateState((ps) => ({
  //       ...ps,
  //       adminRole: role,
  //     }));
  //   } catch (error) {
  //     addError(error);
  //     throw new Error(error);
  //   }
  // };

  useEffect(() => {
    const savedBranchId: string = localStorage.getItem('selectedBranchId');
    const savedBranchName: string = localStorage.getItem('selectedBranchName');
    const savedTenantId: string = localStorage.getItem('tenantId');
    try {
      setLoading(true);

      if (window.location.pathname === '/') {
        if (identity && savedBranchId) {
          history.push(`/branch/${savedBranchId}/dashboard`);
        } else if (identity && !savedBranchId) {
          history.push(`/tenant/ecommerce/dashboard`);
        } else {
          history.push(`/login`);
        }
      }

      if (identity) {
        const isSuperAdmin = identity.roles.find((item) => item.branch === null);
        const data = identity.roles.filter((item) => item.branch !== null);
        selectTenant(savedTenantId);
        if (isSuperAdmin) {
          selectBranch(savedBranchId, savedBranchName);
        } else if (data.length > 1) {
          selectBranch(savedBranchId, savedBranchName);
        } else {
          selectBranch(data[0].branch.id, data[0].branch.name);
        }
        // if (!data) {
        //   selectBranch(savedBranchId, savedBranchName);
        // }
        // if (data) {
        //   selectBranch(data.branch.id, data.branch.name);
        //   setAdminRole(data.name);
        // }
      }
    } catch (error) {
      addError(error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  }, [identity, selectedTenantId]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <AppContext.Provider
      value={{
        isLoading,
        selectedBranchId,
        selectedBranchName,
        selectBranch,
        adminRole,
        selectedTenantId,
        selectTenant,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => React.useContext(AppContext);
