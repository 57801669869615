import { AppRoutes } from '@libs/dto';
import Tenant from '@modules/tenant';
import Branch from '@modules/branch';

import Forbidden from '@pages/Forbidden';
import BadGateway from '@pages/BadGateway';
import NotFound from '@pages/NotFound';
import InternalServer from '@pages/InternalServer';
import Register from '@pages/Register';
import ResetPassword from '@pages/ResetPassword';
import LoginPhone from '@pages/LoginPhone';
import Login from '@pages/Login';
import { registerHostname } from '@config/allowed-hostname';

export const globalRoutes: AppRoutes = [
  {
    id: 1,
    path: '/tenant',
    component: Tenant,
  },
  {
    id: 2,
    path: '/branch',
    component: Branch,
  },
  {
    id: 45,
    path: '/forbidden',
    component: Forbidden,
    exact: true,
  },
  {
    id: 47,
    path: '/bad-gateway',
    component: BadGateway,
    exact: true,
  },
  {
    id: 48,
    path: '/not-found',
    component: NotFound,
    exact: true,
  },
  {
    id: 49,
    path: '/server-error',
    component: InternalServer,
    exact: true,
  },
  ...(registerHostname.findIndex((item) => item === window.location.hostname) !== -1
    ? [
        {
          id: 50,
          path: '/register',
          component: Register,
          exact: true,
        },
      ]
    : []),
  {
    id: 51,
    path: '/reset-password',
    component: ResetPassword,
    exact: true,
  },
  {
    id: 52,
    path: '/login-phone',
    component: LoginPhone,
    exact: true,
  },
  {
    id: 53,
    path: '/login',
    component: Login,
    exact: true,
  },
];
