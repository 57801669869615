import React from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { RequestResetPasswordDto, ResetPasswordDto } from '@libs/dto/admin';
import './ResetPassword.scss';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { WEB_SERVICES } from '../../services';
import { AdminService } from '../../services/admin';
import { useService } from '@hooks/use-service';
import { useNotif } from '@hooks/use-notif';
import ContentHelmet from '@components/ContentHelmet';

const ForgetPassword = () => {
  const { addSuccess } = useNotif();
  const history = useHistory();
  const locations = useLocation();
  const { token, tenant } = queryString.parse(locations.search);
  const adminService = useService<AdminService>(WEB_SERVICES.Admin);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [passwordForm] = Form.useForm();

  const handleRequestResetPassword = async (body: RequestResetPasswordDto) => {
    setLoading(true);
    try {
      await adminService.query('requestResetPassword', [body]);
      addSuccess('Request Success, Please Check Your Email');
      setLoading(false);
      passwordForm.setFieldsValue({
        email: undefined,
      });
      // history.push(`/login`);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleResetPassword = async (body: ResetPasswordDto) => {
    setLoading(true);
    try {
      await adminService.query('resetPassword', [body, token, tenant]);
      addSuccess('Success Reset Password');
      history.push(`/login`);
    } catch (error) {
      setLoading(false);
    }
  };

  const onFinish = (values: { email?: string; newPassword?: string; confirmPassword?: string }) => {
    if (token && tenant) {
      handleResetPassword({
        newPassword: values.newPassword,
      });
    } else {
      handleRequestResetPassword({
        email: values.email,
      });
    }
    // signIn(values);
  };

  const { Title, Text } = Typography;

  return (
    <ContentHelmet title="Reset Password">
      <div className="form">
        <div>
          <div className="logo">
            {/* <img src={LogoDHM} alt="Logo DHM" /> */}
            <Title level={2} className="title-text">
              Reset Password
            </Title>
          </div>

          <Form
            form={passwordForm}
            size="middle"
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onValuesChange={(_changedValues, allValues) => {
              if (allValues.confirmPassword?.length > 0) {
                passwordForm.validateFields();
              }
            }}
          >
            {token && tenant ? (
              <>
                <Form.Item name="newPassword" rules={[{ required: true, message: 'Please input your Password!' }]}>
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="New Password"
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  rules={[
                    {
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject('Please input valid password');
                        }
                        if (value !== passwordForm.getFieldValue('newPassword')) {
                          return Promise.reject('Please input valid password');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Confirm New Password"
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item
                hasFeedback
                name="email"
                rules={[{ required: true, message: 'Please input your Email!' }, { type: 'email' }]}
              >
                <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
              </Form.Item>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                {token && tenant ? 'Change Password' : 'Request Reset Password'}
              </Button>
            </Form.Item>
          </Form>
          <Button onClick={() => history.push('/login')} className="login-form-button" loading={loading}>
            Back
          </Button>
        </div>{' '}
        <div style={{ marginTop: 70 }}>
          <Text style={{ color: 'rgb(125 125 125)', fontSize: 14 }}>
            Don't have any account yet?{' '}
            <span onClick={() => history.push('/register')} style={{ color: '#0050b3', cursor: 'pointer' }}>
              Sign up here
            </span>
          </Text>
        </div>
      </div>
    </ContentHelmet>
  );
};

export default ForgetPassword;
