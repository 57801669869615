import Axios, { AxiosRequestConfig } from 'axios';
import { useNotif } from '@hooks/use-notif';

export const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const { addError } = useNotif();
axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const getToken = localStorage.getItem('token');
  const getTenantId = localStorage.getItem('tenantId');
  return {
    ...config,
    headers: {
      Authorization: `Bearer ${getToken}`,
      'x-tenant-id': getTenantId,
      ...config.headers,
    },
  };
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const allowedPathname = ['/register', '/reset-password', '/login-phone', '/login'];
    // eslint-disable-next-line no-console
    if (error.response?.status === 401) {
      if (allowedPathname.findIndex((path) => path === window.location.pathname) === -1) {
        localStorage.clear();
        window.location.href = '/login';
      }
    } else if (error.response?.status === 403) {
      if (
        error?.response?.config?.method !== 'post' &&
        error?.response?.config?.method !== 'put' &&
        error?.response?.config?.method !== 'delete'
      ) {
        // localStorage.clear();
        window.location.href = '/forbidden';
      }
    } else if (error.response?.status === 502) {
      if (
        error?.response?.config?.method !== 'post' &&
        error?.response?.config?.method !== 'put' &&
        error?.response?.config?.method !== 'delete'
      ) {
        // localStorage.clear();
        window.location.href = '/bad-gateway';
      }
    } else if (error.response?.status === 500) {
      if (
        error?.response?.config?.method !== 'post' &&
        error?.response?.config?.method !== 'put' &&
        error?.response?.config?.method !== 'delete'
      ) {
        // window.location.href = '/server-error';
      }
      // localStorage.clear();
      // window.location.href = '/server-error';
    } else if (error.response?.status === 404) {
      if (
        error?.response?.config?.method !== 'post' &&
        error?.response?.config?.method !== 'put' &&
        error?.response?.config?.method !== 'delete'
      ) {
        window.location.href = '/not-found';
      }
      // localStorage.clear();
    } else if (error.response?.status === 400) {
    } else if (error.response?.status === 422) {
    } else if (error.message === 'canceled') {
    } else {
      if (
        error?.response?.config?.method !== 'post' &&
        error?.response?.config?.method !== 'put' &&
        error?.response?.config?.method !== 'delete'
      ) {
        window.location.href = '/forbidden';
      }
    }
    if (error.message !== 'canceled') {
      addError(error);
    }
    // eslint-disable-next-line no-alert
    return Promise.reject(error);
  }
);
