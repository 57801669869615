export enum ArticlePermission {
  CREATE = 'CREATE_CMS_ARTICLE',
  READ = 'READ_CMS_ARTICLE',
  UPDATE = 'UPDATE_CMS_ARTICLE',
  DELETE = 'DELETE_CMS_ARTICLE',
}

export enum ArticleCategoryPermission {
  CREATE = 'CREATE_CMS_CATEGORY',
  READ = 'READ_CMS_CATEGORY',
  UPDATE = 'UPDATE_CMS_CATEGORY',
  DELETE = 'DELETE_CMS_CATEGORY',
}

export enum ArticleMenuPermission {
  CREATE = 'CREATE_CMS_MENU',
  READ = 'READ_CMS_MENU',
  UPDATE = 'UPDATE_CMS_MENU',
  DELETE = 'DELETE_CMS_MENU',
}

export enum ArticleTagPermission {
  CREATE = 'CREATE_CMS_TAG',
  READ = 'READ_CMS_TAG',
  UPDATE = 'UPDATE_CMS_TAG',
  DELETE = 'DELETE_CMS_TAG',
}

export enum BannerPermission {
  CREATE = 'CREATE_ECOMMERCE_BANNER',
  READ = 'READ_ECOMMERCE_BANNER',
  UPDATE = 'UPDATE_ECOMMERCE_BANNER',
  DELETE = 'DELETE_ECOMMERCE_BANNER',
}

export enum FaqCategoryPermission {
  CREATE = 'CREATE_CMS_FAQ_CATEGORY',
  READ = 'READ_CMS_FAQ_CATEGORY',
  UPDATE = 'UPDATE_CMS_FAQ_CATEGORY',
  DELETE = 'DELETE_CMS_FAQ_CATEGORY',
}

export enum FaqPermission {
  CREATE = 'CREATE_CMS_FAQ',
  READ = 'READ_CMS_FAQ',
  UPDATE = 'UPDATE_CMS_FAQ',
  DELETE = 'DELETE_CMS_FAQ',
}
