import React from 'react';
import { AppRoutes } from '@libs/dto';
import { branchEcommerce, productEcommerce } from './routes.config';

const FoodDashboard = React.lazy(() => import('@modules/ecommerce/pages/Dashboard'));
const FoodSetting = React.lazy(() => import('@modules/ecommerce/pages/Setting'));
const FoodAdd = React.lazy(() => import('@modules/ecommerce/pages/Products/Products/Add'));
const FoodEdit = React.lazy(() => import('@modules/ecommerce/pages/Products/Products/Edit'));
const FoodOrderList = React.lazy(() => import('@modules/ecommerce/pages/Orders/List'));
const FoodOrderDetail = React.lazy(() => import('@modules/ecommerce/pages/Orders/Detail'));
const PaymentConfigure = React.lazy(() => import('@modules/ecommerce/pages/Setting/Payment/Gateway/Configure'));
const ShipmentConfigure = React.lazy(() => import('@modules/ecommerce/pages/Setting/Shipping/Gateway/Configure'));

export const routes: AppRoutes = [
  {
    id: 20,
    path: `${branchEcommerce}/dashboard`,
    component: FoodDashboard,
    exact: true,
  },
  {
    id: 27,
    path: `${branchEcommerce}/settings`,
    component: FoodSetting,
    exact: true,
  },
  {
    id: 28,
    path: `${productEcommerce}/add`,
    component: FoodAdd,
    exact: true,
  },
  {
    id: 29,
    path: `${productEcommerce}/edit/:product_id`,
    component: FoodEdit,
  },
  {
    id: 30,
    path: `${branchEcommerce}/orders`,
    component: FoodOrderList,
    exact: true,
  },
  {
    id: 31,
    path: `${branchEcommerce}/orders/:order_id`,
    component: FoodOrderDetail,
    exact: true,
  },
  {
    id: 34,
    path: `${branchEcommerce}/payment-gateways/:name`,
    component: PaymentConfigure,
    exact: true,
  },
  {
    id: 35,
    path: `${branchEcommerce}/shipment-gateways/:name`,
    component: ShipmentConfigure,
    exact: true,
  },
];
