import { defineAbility } from '@casl/ability';
import { TenantPermissionSubject } from './libs/enum/permission-subject';
import { TenantPermission, UserPermission } from './libs/enum/permission';

export default defineAbility((can, _cannot) => {
  /// Tenant
  can(TenantPermission.READ, TenantPermissionSubject.TENANT);
  can(TenantPermission.CREATE, TenantPermissionSubject.TENANT);
  can(TenantPermission.UPDATE, TenantPermissionSubject.TENANT);
  can(TenantPermission.DELETE, TenantPermissionSubject.TENANT);

  /// User
  can(UserPermission.READ, TenantPermissionSubject.USER);
  can(UserPermission.CREATE, TenantPermissionSubject.USER);
  can(UserPermission.UPDATE, TenantPermissionSubject.USER);
  can(UserPermission.DELETE, TenantPermissionSubject.USER);
});
