/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Layout, Menu, Modal, Spin, Divider } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppMenu, TenantDto } from '@libs/dto';
import './Sidebar.scss';
import { useApp } from '@context/app-context';
import defaultWarungIcon from '@assets/defaultWarungIcon.svg';
import { useAuth } from '@context/auth-context';
import { permission } from '@utils/permission';
import MenuIcon from '@assets/menu.svg';
import HomeIcon from '@assets/home.svg';
import { WEB_SERVICES } from '../../services';
import { TenantService } from '../../services/tenant';
import { useService } from '@hooks/use-service';
import AddIcon from '@assets/AddTenant.svg';
import ChecklistIcon from '@assets/Checklist.svg';
import { registerHostname } from '@config/allowed-hostname';
import { withTransaction } from '@elastic/apm-rum-react';

const { Sider } = Layout;
const { SubMenu } = Menu;
interface SidebarProps {
  menus: AppMenu;
}

const Sidebar: React.FC<SidebarProps> = ({ menus }) => {
  const { selectBranch, selectTenant, selectedTenantId } = useApp();
  const { tenantProfile, setTenantProfile } = useAuth();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  // eslint-disable-next-line
  const [selectedAppKey, setSelectedAppKey] = useState('2');
  const [drawerTenant, setDrawerTenant] = useState(false);
  const tenantService = useService<TenantService>(WEB_SERVICES.Tenant);
  const [tenants, setTenants] = useState<TenantDto[]>([]);

  const history = useHistory();

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onCollapse = (_collapsed: boolean) => {
    setCollapsed(_collapsed);
  };

  const getListTenant = async () => {
    const tenantListResponse: { data: TenantDto[] } = await tenantService.query('list', [
      { page: '1', perPage: Number.MAX_SAFE_INTEGER.toString() },
    ]);

    const move = (from, to, ...a) => (from === to ? a : (a.splice(to, 0, ...a.splice(from, 1)), a));

    let newArray = tenantListResponse.data;
    const indexOfSelected = newArray.findIndex((item) => item.id === selectedTenantId);

    if (indexOfSelected !== -1) {
      newArray = move(indexOfSelected, 0, ...tenantListResponse.data);
    }

    setTenants(newArray);
  };

  React.useEffect(() => {
    if (drawerTenant) {
      getListTenant();
    }
    // eslint-disable-next-line
  }, [drawerTenant]);

  React.useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  return (
    <Sider
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 999,
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      trigger={null}
    >
      {/* <img src={Logo} alt="logo" className="logo" /> */}

      <div style={{ display: 'flex' }}>
        <div className="parent-side">
          <Menu
            className="menu-parent-side"
            style={{ width: 50 }}
            defaultSelectedKeys={[selectedAppKey]}
            selectedKeys={[selectedAppKey]}
            mode="inline"
            theme="dark"
          >
            <Menu.Item className="parent-menu" key="1">
              <img alt="tes" src={MenuIcon} />
            </Menu.Item>
            <Menu.Item
              style={{
                borderRadius: '0px 7px 7px 0px !important',
              }}
              className="parent-menu"
              key="2"
            >
              <img alt="tes" src={HomeIcon} />
            </Menu.Item>
          </Menu>
        </div>
        <div
          style={{
            width: '100%',
            // paddingRight: 24,
            paddingLeft: 5,
          }}
        >
          <div className="select-branch">
            <div
              onClick={() => {
                if (registerHostname.findIndex((item) => item === window.location.hostname) !== -1) {
                  setDrawerTenant(true);
                }
              }}
              className="title-tenant"
            >
              <div className="title-tenant-image-and-title">
                {tenantProfile?.images?.length ? (
                  <img
                    src={tenantProfile.images[0].url}
                    style={{ width: 25, height: 25, objectFit: 'cover' }}
                    alt={tenantProfile.name}
                  />
                ) : (
                  <img src={defaultWarungIcon} alt="Warung Icon" />
                )}
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '10ch',
                    fontSize: 14,
                    color: 'white',
                  }}
                >
                  {tenantProfile?.name || 'Warung'}
                </div>
              </div>

              <RightOutlined className="title-text" />
            </div>
          </div>
          <Menu
            theme="dark"
            defaultSelectedKeys={[selectedKey]}
            selectedKeys={[selectedKey]}
            // openKeys={['2']}
            mode="inline"
            forceSubMenuRender
          >
            {menus.map((menu) => {
              if (menu.permission) {
                const checkPermission = menu.permission.map((item) =>
                  permission(item.ability, item.action, item.subject).toString()
                );
                const isAnyTrue = checkPermission.indexOf('true');

                if (isAnyTrue === -1) {
                  return null;
                }
              }

              if (menu.hidden) {
                return null;
              }

              if (menu.isParent && menu.subMenu) {
                return (
                  <SubMenu key={`${menu.linkTo}`} icon={menu.Icon && <menu.Icon />} title={menu.title}>
                    {menu.subMenu.map((sub) => {
                      if (sub.permission) {
                        const checkPermission = sub.permission.map((item) =>
                          permission(item.ability, item.action, item.subject).toString()
                        );
                        const isAnyTrue = checkPermission.indexOf('true');

                        if (isAnyTrue === -1) {
                          return null;
                        }
                      }
                      if (sub.hidden) {
                        return null;
                      }
                      return (
                        <Menu.Item key={`${sub.linkTo}`} icon={sub.Icon && <sub.Icon />}>
                          <Link onClick={() => setSelectedKey(`${sub.linkTo}`)} to={sub.linkTo}>
                            {sub.title}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              }
              return (
                <Menu.Item className="sidebar-menu" key={`${menu.linkTo}`} icon={menu.Icon && <menu.Icon />}>
                  {menu.linkTo ? (
                    <Link onClick={() => setSelectedKey(`${menu.linkTo}`)} to={menu.linkTo}>
                      {menu.title}
                    </Link>
                  ) : (
                    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                    <a
                      role="button"
                      onClick={() => {
                        if (menu.action) {
                          menu.action();
                        }
                        if (menu.title === 'Super Admin') {
                          selectBranch(null, null);
                          history.push('/tenant/dashboard');
                        }
                      }}
                    >
                      {menu.title}
                    </a>
                  )}
                </Menu.Item>
              );
            })}
            <Modal
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={tenantProfile?.images?.[0]?.url || defaultWarungIcon}
                    style={{ width: 30, height: 30, objectFit: 'cover', backgroundColor: 'black' }}
                    alt={tenantProfile?.name || 'Warung Icon'}
                  />
                  <div
                    style={{
                      fontSize: 14,
                      marginLeft: 20,
                    }}
                  >
                    {tenantProfile?.name || 'Warung'}
                  </div>
                </div>
              }
              className="list-tenant"
              mask={false}
              style={{ left: 65, top: 10, position: 'absolute', zIndex: 9999 }}
              visible={drawerTenant}
              // onOk={() => this.setModal1Visible(false)}
              onCancel={() => setDrawerTenant(false)}
              footer={false}
            >
              <div>
                <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
                  {tenantService.isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 100 }}>
                      <Spin />
                    </div>
                  ) : (
                    <>
                      {tenants?.map((item) => (
                        <div
                          onClick={() => {
                            selectTenant(item.id);
                            setTenantProfile(item);
                            setDrawerTenant(false);
                          }}
                          className="tenant-list"
                          style={{
                            backgroundColor: selectedTenantId === item.id ? '#F1F5F9' : 'white',
                          }}
                        >
                          {item.name}
                          {selectedTenantId === item.id && <img alt="addTenant" src={ChecklistIcon} />}
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <Divider />
                <div
                  onClick={() => {
                    setDrawerTenant(false);
                    history.push('/tenant/add');
                  }}
                  className="add-tenant"
                >
                  {' '}
                  <img alt="addTenant" src={AddIcon} style={{ marginRight: 10 }} />
                  Add New Company
                </div>
              </div>
            </Modal>
          </Menu>
        </div>
      </div>
    </Sider>
  );
};

export default withTransaction('Sidebar', 'component')(Sidebar);
