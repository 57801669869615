import { defineAbility } from '@casl/ability';
import { EcommercePermissionSubject } from './libs/enum/permission-subject';
import {
  ReportPermission,
  CustomerPermission,
  LocationPermission,
  ProductPermission,
  ProductCategoryPermission,
  OrderPermission,
  SalesChannelPermission,
  VoucherPermission,
  ProductReviewPermission,
  PaymentGatewayPermission,
  PaymentChannelPermission,
  ShippingGatewayPermission,
  ShippingChannelPermission,
  CustomerGroupPermission,
} from './libs/enum/permission';

export default defineAbility((can, _cannot) => {
  /// Report
  can(ReportPermission.READ, EcommercePermissionSubject.REPORT);

  /// Customer
  can(CustomerPermission.READ, EcommercePermissionSubject.CUSTOMER);
  can(CustomerPermission.CREATE, EcommercePermissionSubject.CUSTOMER);
  can(CustomerPermission.DELETE, EcommercePermissionSubject.CUSTOMER);
  can(CustomerPermission.UPDATE, EcommercePermissionSubject.CUSTOMER);

  /// Location
  can(LocationPermission.READ, EcommercePermissionSubject.LOCATION);
  can(LocationPermission.CREATE, EcommercePermissionSubject.LOCATION);
  can(LocationPermission.DELETE, EcommercePermissionSubject.LOCATION);
  can(LocationPermission.UPDATE, EcommercePermissionSubject.LOCATION);

  /// Product
  can(ProductPermission.READ, EcommercePermissionSubject.PRODUCT);
  can(ProductPermission.CREATE, EcommercePermissionSubject.PRODUCT);
  can(ProductPermission.DELETE, EcommercePermissionSubject.PRODUCT);
  can(ProductPermission.UPDATE, EcommercePermissionSubject.PRODUCT);

  /// Product
  can(ProductPermission.READ, EcommercePermissionSubject.PRODUCT);
  can(ProductPermission.CREATE, EcommercePermissionSubject.PRODUCT);
  can(ProductPermission.DELETE, EcommercePermissionSubject.PRODUCT);
  can(ProductPermission.UPDATE, EcommercePermissionSubject.PRODUCT);

  /// Product Category
  can(ProductCategoryPermission.READ, EcommercePermissionSubject.PRODUCT_CATEGORY);
  can(ProductCategoryPermission.CREATE, EcommercePermissionSubject.PRODUCT_CATEGORY);
  can(ProductCategoryPermission.DELETE, EcommercePermissionSubject.PRODUCT_CATEGORY);
  can(ProductCategoryPermission.UPDATE, EcommercePermissionSubject.PRODUCT_CATEGORY);

  /// Product Category
  can(OrderPermission.READ, EcommercePermissionSubject.ORDER);
  can(OrderPermission.CREATE, EcommercePermissionSubject.ORDER);
  can(OrderPermission.UPDATE, EcommercePermissionSubject.ORDER);

  /// Sales Channels
  can(SalesChannelPermission.READ, EcommercePermissionSubject.SALES_CHANNEL);
  can(SalesChannelPermission.CREATE, EcommercePermissionSubject.SALES_CHANNEL);
  can(SalesChannelPermission.DELETE, EcommercePermissionSubject.SALES_CHANNEL);
  can(SalesChannelPermission.UPDATE, EcommercePermissionSubject.SALES_CHANNEL);

  /// Voucher
  can(VoucherPermission.READ, EcommercePermissionSubject.VOUCHER);
  can(VoucherPermission.CREATE, EcommercePermissionSubject.VOUCHER);
  can(VoucherPermission.DELETE, EcommercePermissionSubject.VOUCHER);
  can(VoucherPermission.UPDATE, EcommercePermissionSubject.VOUCHER);

  /// Product Review
  can(ProductReviewPermission.READ, EcommercePermissionSubject.PRODUCT_REVIEW);
  can(ProductReviewPermission.CREATE, EcommercePermissionSubject.PRODUCT_REVIEW);
  can(ProductReviewPermission.DELETE, EcommercePermissionSubject.PRODUCT_REVIEW);
  can(ProductReviewPermission.UPDATE, EcommercePermissionSubject.PRODUCT_REVIEW);

  /// Payment Gateway
  can(PaymentGatewayPermission.READ, EcommercePermissionSubject.PAYMENT_GATEWAY);
  can(PaymentGatewayPermission.CREATE, EcommercePermissionSubject.PAYMENT_GATEWAY);
  can(PaymentGatewayPermission.DELETE, EcommercePermissionSubject.PAYMENT_GATEWAY);
  can(PaymentGatewayPermission.UPDATE, EcommercePermissionSubject.PAYMENT_GATEWAY);

  /// Payment Channel
  can(PaymentChannelPermission.READ, EcommercePermissionSubject.PAYMENT_CHANNEL);
  can(PaymentChannelPermission.CREATE, EcommercePermissionSubject.PAYMENT_CHANNEL);
  can(PaymentChannelPermission.DELETE, EcommercePermissionSubject.PAYMENT_CHANNEL);
  can(PaymentChannelPermission.UPDATE, EcommercePermissionSubject.PAYMENT_CHANNEL);

  /// Shipping Gateway
  can(ShippingGatewayPermission.READ, EcommercePermissionSubject.SHIPPING_GATEWAY);
  can(ShippingGatewayPermission.CREATE, EcommercePermissionSubject.SHIPPING_GATEWAY);
  can(ShippingGatewayPermission.DELETE, EcommercePermissionSubject.SHIPPING_GATEWAY);
  can(ShippingGatewayPermission.UPDATE, EcommercePermissionSubject.SHIPPING_GATEWAY);

  /// Shipping Channel
  can(ShippingChannelPermission.READ, EcommercePermissionSubject.SHIPPING_CHANNEL);
  can(ShippingChannelPermission.CREATE, EcommercePermissionSubject.SHIPPING_CHANNEL);
  can(ShippingChannelPermission.DELETE, EcommercePermissionSubject.SHIPPING_CHANNEL);
  can(ShippingChannelPermission.UPDATE, EcommercePermissionSubject.SHIPPING_CHANNEL);

  /// Customer Groub
  can(CustomerGroupPermission.READ, EcommercePermissionSubject.CUSTOMER_GROUP);
  can(CustomerGroupPermission.CREATE, EcommercePermissionSubject.CUSTOMER_GROUP);
  can(CustomerGroupPermission.DELETE, EcommercePermissionSubject.CUSTOMER_GROUP);
  can(CustomerGroupPermission.UPDATE, EcommercePermissionSubject.CUSTOMER_GROUP);
});
