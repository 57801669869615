import { axiosInstance } from '@config/axios-instance';
import { tenant, tenants } from '../config';
import { TenatPayloadDto, AddTenantPayloadDto } from '@libs/dto';

export const tenantService = {
  list({ page = '1', perPage = '10' }: { page?: string; perPage?: string }) {
    return axiosInstance.get(`${tenants}`, {
      params: {
        page,
        perPage,
      },
    });
  },
  validatePhone(data: { phone: string }) {
    return axiosInstance.post(`${tenant}/auth/check-user-availability-by-phone`, data);
  },
  register(data: TenatPayloadDto) {
    return axiosInstance.post(`${tenant}/register`, data);
  },
  create(data: AddTenantPayloadDto) {
    return axiosInstance.post(`${tenant}`, data);
  },
};

export type TenantService = typeof tenantService;
