import React from 'react';
import ContentHelmet from '@components/ContentHelmet';
import './InternalServer.scss';
import ErrorImage from '@assets/500.jpg';
import { Layout } from 'antd';
import Sidebar from '@components/Sidebar/Sidebar';
import { tenantMenus } from '@modules/tenant/menus';
import { AppMenu } from '@libs/dto';
import Header from '@components/Header';

export default function InternalServer() {
  const sidebarMenus: AppMenu = tenantMenus;
  return (
    <ContentHelmet title="Internal Server Page">
      <Sidebar menus={sidebarMenus} />
      <Header breadcrumbItems={[]} />
      <Layout className="site-layout">
        <div className="container-error">
          <img src={ErrorImage} alt="error" className="error" style={{ width: 700 }} />
        </div>
      </Layout>
    </ContentHelmet>
  );
}
