import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Typography } from 'antd';
import { AdminSignInPhoneDto } from '@libs/dto';
import './LoginPhone.scss';
import PhoneInput, { CountryPhoneInputValue } from '@components/PhoneInput/PhoneInput';
import OtpInput from 'react-otp-input';
import firebase from '@config/firebase';
import 'firebase/auth';
import { useNotif } from '@hooks/use-notif';
import { useAuth } from '@context/auth-context';
import { formatPhoneNumber } from '@utils/format-phone-number';
import { useHistory } from 'react-router-dom';
import ContentHelmet from '@components/ContentHelmet';
import { WEB_SERVICES } from '../../services';
import { AdminService } from '../../services/admin';
import { useService } from '@hooks/use-service';

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
    grecaptcha: any;
  }
}

const LoginPhone = () => {
  const history = useHistory();
  const { addError, addSuccess } = useNotif();
  const { signInPhoneFirebase, isError, isSubmitted, signInWhatsApp } = useAuth();
  const submitButton = useRef(null);
  const [phoneForm] = Form.useForm();
  const { Title, Text } = Typography;
  const [loading, setLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<boolean>(false);
  const [clearCaptha, setClearCaptha] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const phoneRegex = /(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
  const [usedPhone, setUsedPhone] = useState('');
  const [countdown, setCountDown] = useState<number>(null);
  const adminService = useService<AdminService>(WEB_SERVICES.Admin);
  const [phoneMethod, setPhoneMethod] = useState('');

  // const registerData = JSON.parse(localStorage.getItem('registerData'));
  // const confirmationResult = JSON.parse(localStorage.getItem('confirmationResult'));
  const onSignInSubmit = (phone: string) => {
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber('+' + phone, appVerifier)
      .then((confirmationResult) => {
        localStorage.setItem('confirmationResult', JSON.stringify(confirmationResult));
        setOtp(true);
        setCountDown(60);
        setLoading(false);
        addSuccess('OTP Code Sent');
        setUsedPhone(phone);
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        window.recaptchaVerifier.render().then(function (widgetId) {
          window.grecaptcha.reset(widgetId);
        });
        addError(error?.message);
        setClearCaptha(!clearCaptha);
        console.log(error);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (isError) {
      setLoading(false);
      setValue('');
    }
  }, [isError, phoneForm]);

  useEffect(() => {
    if (!otp) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
        size: 'invisible',
        callback: () => {
          setLoading(true);
        },
      });
      submitButton.current.innerHTML = `<div id="sign-in-button"></div>`;
    }
  }, [otp, clearCaptha]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0) {
        if (otp) {
          setCountDown(countdown - 1);
        }
      }
    }, 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [countdown]);

  const handleSendOtpWhatsApp = async () => {
    setPhoneMethod('WhatsApp');
    phoneForm.validateFields().then(async (values) => {
      setLoading(true);
      const phoneNumber = formatPhoneNumber(values?.phone);
      try {
        await adminService.query('requestOtpWhatsApp', [{ phone: phoneNumber }]);
        setOtp(true);
        setCountDown(60);
        setLoading(false);
        addSuccess('OTP Code Sent');
        setUsedPhone(phoneNumber);
        // addSuccess('Success add location!');
        // history.push(`/tenant/settings?tab=locations`);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    });
  };

  const handleReSendOtpWhatsApp = async (phone: string) => {
    setLoading(true);
    try {
      await adminService.query('requestOtpWhatsApp', [{ phone: phone }]);
      setOtp(true);
      setCountDown(60);
      setLoading(false);
      addSuccess('OTP Code Sent');
      // addSuccess('Success add location!');
      // history.push(`/tenant/settings?tab=locations`);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onFinish = async (values) => {
    setPhoneMethod('Firebase');
    setLoading(true);
    const phoneNumber = formatPhoneNumber(values?.phone);
    onSignInSubmit(phoneNumber);
  };

  const handleResendOTP = () => {
    if (phoneMethod === 'WhatsApp') {
      handleReSendOtpWhatsApp(usedPhone);
      return;
    }
    onSignInSubmit(usedPhone);
  };

  const handleConfirmOtp = () => {
    setLoading(true);
    const code = value;
    if (phoneMethod === 'WhatsApp') {
      signInWhatsApp({
        phone: usedPhone,
        otp: code,
      });
    } else {
      window.confirmationResult
        .confirm(code)
        .then(async (result) => {
          const googleToken = await result.user.getIdToken();
          const body: AdminSignInPhoneDto = {
            token: googleToken,
          };
          signInPhoneFirebase(body);
        })
        .catch((error) => {
          setValue('');
          setLoading(false);
          addError(error?.message);
          console.log(error);
          // User couldn't sign in (bad verification code?)
          // ...
        });
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [isError]);

  return (
    <ContentHelmet title="Login">
      <div className="form">
        <div>
          <div className="logo">
            {/* <img src={LogoDHM} alt="Logo DHM" /> */}
            <Title level={2} className="title-text">
              {process.env.REACT_APP_TITLE || 'Warung'}
            </Title>
          </div>

          {otp ? (
            <>
              <OtpInput
                isInputNum
                inputStyle={{
                  width: 50,
                  borderRadius: 5,
                  border: '2px solid black',
                }}
                containerStyle={{
                  marginBottom: 20,
                  justifyContent: 'center',
                }}
                value={value}
                onChange={(otp) => setValue(otp)}
                numInputs={6}
                separator={<span>-</span>}
              />
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
                <Text style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: 14 }}>
                  OTP has been sent to <b>+{usedPhone}</b>
                </Text>
              </div>
              <Button
                loading={loading || isSubmitted}
                onClick={handleConfirmOtp}
                disabled={value.length < 6}
                id="sign-in-button"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Confirm OTP
              </Button>

              <Button
                style={{ marginTop: 20 }}
                loading={loading || isSubmitted}
                onClick={handleResendOTP}
                disabled={countdown > 0}
                id="sign-in-button"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Resend OTP {countdown !== 0 && '(' + countdown + ')'}
              </Button>
              <Button
                disabled={loading || isSubmitted}
                style={{ marginTop: 20 }}
                onClick={() => {
                  setOtp(false);
                  setValue('');
                  setCountDown(null);
                }}
                id="sign-in-button"
                htmlType="submit"
                className="login-form-button"
              >
                Back
              </Button>
            </>
          ) : (
            <Form
              form={phoneForm}
              size="middle"
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="phone"
                rules={[
                  // { required: true, message: 'Invalid phone!' },
                  {
                    validator(_, value: CountryPhoneInputValue) {
                      if (phoneRegex.test(value.phone)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('The phone number is not valid!');
                      }
                    },
                  },
                ]}
              >
                <PhoneInput placeholder="Enter phone number" />
              </Form.Item>

              <div style={{ display: 'flex' }}>
                <Form.Item>
                  <Button
                    loading={(loading || isSubmitted) && phoneMethod === 'Firebase'}
                    disabled={(loading || isSubmitted) && phoneMethod !== 'Firebase'}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Login with SMS
                  </Button>
                </Form.Item>
                <Button
                  loading={(loading || isSubmitted) && phoneMethod === 'WhatsApp'}
                  disabled={(loading || isSubmitted) && phoneMethod !== 'WhatsApp'}
                  // type="primary"
                  onClick={handleSendOtpWhatsApp}
                  className="login-form-whatsapp"
                >
                  Login with WhatsApp
                </Button>
              </div>

              <Button onClick={() => history.push('/login')} className="login-form-button">
                Login with Email
              </Button>
            </Form>
          )}
        </div>{' '}
        <div ref={submitButton}>
          <div id="sign-in-button"></div>
        </div>
        <div style={{ marginTop: 70 }}>
          <Text style={{ color: 'rgb(125 125 125)', fontSize: 14 }}>
            Don't have any account yet?{' '}
            <span onClick={() => history.push('/register')} style={{ color: '#0050b3', cursor: 'pointer' }}>
              Sign up here
            </span>
          </Text>
        </div>
      </div>
    </ContentHelmet>
  );
};

export default LoginPhone;
