import React from 'react';
import { AppRoutes } from '@libs/dto';
import { ecommerce } from './routes.config';

const FoodDashboard = React.lazy(() => import('@modules/ecommerce/pages/Dashboard'));
const FoodList = React.lazy(() => import('@modules/ecommerce/pages/Products/Products'));
const FoodCategories = React.lazy(() => import('@modules/ecommerce/pages/Products/Categories'));
const FoodAdd = React.lazy(() => import('@modules/ecommerce/pages/Products/Products/Add'));
const FoodEdit = React.lazy(() => import('@modules/ecommerce/pages/Products/Products/Edit'));

const FoodOrderList = React.lazy(() => import('@modules/ecommerce/pages/Orders/List'));
const FoodOrderDetail = React.lazy(() => import('@modules/ecommerce/pages/Orders/Detail'));
const FoodOrderAdd = React.lazy(() => import('@modules/ecommerce/pages/Orders/Add'));
const FoodOrderEdit = React.lazy(() => import('@modules/ecommerce/pages/Orders/Edit'));
const PaymentConfigure = React.lazy(() => import('@modules/ecommerce/pages/Setting/Payment/Gateway/Configure'));
const PaymentChannelConfigure = React.lazy(() => import('@modules/ecommerce/pages/Setting/Payment/Channel/Configure'));
const ShipmentConfigure = React.lazy(() => import('@modules/ecommerce/pages/Setting/Shipping/Gateway/Configure'));
const ShippingChannelConfigure = React.lazy(
  () => import('@modules/ecommerce/pages/Setting/Shipping/Channel/Configure')
);

const InventoryList = React.lazy(() => import('@modules/ecommerce/pages/Products/Inventory'));
const AddLocation = React.lazy(() => import('@modules/ecommerce/pages/Setting/Location/Add'));
const EditLocation = React.lazy(() => import('@modules/ecommerce/pages/Setting/Location/Edit'));

const VoucherList = React.lazy(() => import('@modules/ecommerce/pages/Vouchers/List'));
const VoucherAdd = React.lazy(() => import('@modules/ecommerce/pages/Vouchers/Add'));
const VoucherEdit = React.lazy(() => import('@modules/ecommerce/pages/Vouchers/Edit'));

const ReviewsList = React.lazy(() => import('@modules/ecommerce/pages/Reviews'));

// const SalesChannels = React.lazy(() => import('@modules/ecommerce/pages/SalesChannels'));
// const SalesChannelAdd = React.lazy(() => import('@modules/ecommerce/pages/SalesChannels/Add'));

// const WebApiAdd = React.lazy(() => import('@modules/ecommerce/pages/SalesChannels/WebApi/Add'));
// const WebApiDetail = React.lazy(() => import('@modules/ecommerce/pages/SalesChannels/WebApi/Detail/Settings'));
// const WebApiPaymentConfigure = React.lazy(
//   () => import('@modules/ecommerce/pages/SalesChannels/WebApi/Detail/Settings/Payments/Configure')
// );
// const WebApiShippingConfigure = React.lazy(
//   () => import('@modules/ecommerce/pages/SalesChannels/WebApi/Detail/Settings/Shippings/Configure')
// );

// const MobileApiAdd = React.lazy(() => import('@modules/ecommerce/pages/SalesChannels/MobileApi/Add'));
// const MobileApiDetail = React.lazy(() => import('@modules/ecommerce/pages/SalesChannels/MobileApi/Detail/Settings'));

const CustomerList = React.lazy(() => import('@modules/ecommerce/pages/Customers'));
const CustomerAdd = React.lazy(() => import('@modules/ecommerce/pages/Customers/Add'));
const CustomerDetail = React.lazy(() => import('@modules/ecommerce/pages/Customers/Detail'));
const CustomerGroups = React.lazy(() => import('@modules/ecommerce/pages/Customers/Groups'));

export const routes: AppRoutes = [
  {
    id: 19,
    path: `${ecommerce}/dashboard`,
    component: FoodDashboard,
    exact: true,
  },
  {
    id: 20,
    path: `${ecommerce}/orders`,
    component: FoodOrderList,
    exact: true,
  },
  {
    id: 21,
    path: `${ecommerce}/products`,
    component: FoodList,
    exact: true,
  },
  {
    id: 22,
    path: `${ecommerce}/products/categories`,
    component: FoodCategories,
    exact: true,
  },
  {
    id: 23,
    path: `${ecommerce}/products/add`,
    component: FoodAdd,
    exact: true,
  },
  {
    id: 24,
    path: `${ecommerce}/products/:product_id/edit`,
    component: FoodEdit,
  },
  {
    id: 25,
    path: `${ecommerce}/orders/:ecommerce_id`,
    component: FoodOrderDetail,
    exact: true,
  },
  {
    id: 26,
    path: `${ecommerce}/payment-gateways/:name`,
    component: PaymentConfigure,
    exact: true,
  },
  {
    id: 27,
    path: `${ecommerce}/payment-gateways/:name/payment-channels/:channel`,
    component: PaymentChannelConfigure,
    exact: true,
  },
  {
    id: 28,
    path: `${ecommerce}/shipping-gateways/:name`,
    component: ShipmentConfigure,
    exact: true,
  },
  {
    id: 29,
    path: `${ecommerce}/orders/add`,
    component: FoodOrderAdd,
    exact: true,
  },
  {
    id: 30,
    path: `${ecommerce}/inventory`,
    component: InventoryList,
  },
  {
    id: 31,
    path: `${ecommerce}/locations/add`,
    component: AddLocation,
    exact: true,
  },
  {
    id: 32,
    path: `${ecommerce}/locations/:id/edit`,
    component: EditLocation,
    exact: true,
  },
  {
    id: 33,
    path: `${ecommerce}/orders/:ecommerce_id/edit`,
    component: FoodOrderEdit,
    exact: true,
  },
  {
    id: 34,
    path: `${ecommerce}/marketing/vouchers`,
    component: VoucherList,
    exact: true,
  },
  {
    id: 35,
    path: `${ecommerce}/marketing/vouchers/add`,
    component: VoucherAdd,
    exact: true,
  },
  {
    id: 36,
    path: `${ecommerce}/marketing/vouchers/:voucher_id/edit`,
    component: VoucherEdit,
    exact: true,
  },
  {
    id: 37,
    path: `${ecommerce}/reviews`,
    component: ReviewsList,
    exact: true,
  },
  {
    id: 38,
    path: `${ecommerce}/shipping-gateways/:name/shipping-channels/:channel`,
    component: ShippingChannelConfigure,
    exact: true,
  },
  // {
  //   id: 39,
  //   path: `${ecommerce}/sales-channels`,
  //   component: SalesChannels,
  //   exact: true,
  // },
  // {
  //   id: 40,
  //   path: `${ecommerce}/sales-channels/add`,
  //   component: SalesChannelAdd,
  //   exact: true,
  // },
  // {
  //   id: 41,
  //   path: `${ecommerce}/sales-channels/web-api/add`,
  //   component: WebApiAdd,
  //   exact: true,
  // },
  // {
  //   id: 42,
  //   path: `${ecommerce}/sales-channels/web-api/:id/settings`,
  //   component: WebApiDetail,
  //   exact: true,
  // },
  // {
  //   id: 43,
  //   path: `${ecommerce}/sales-channels/mobile-api/add`,
  //   component: MobileApiAdd,
  //   exact: true,
  // },
  // {
  //   id: 44,
  //   path: `${ecommerce}/sales-channels/mobile-api/:id/settings`,
  //   component: MobileApiDetail,
  //   exact: true,
  // },
  // {
  //   id: 45,
  //   path: `${ecommerce}/sales-channels/:type/:id/settings/payment-channels/:payment_channel_id`,
  //   component: WebApiPaymentConfigure,
  //   exact: true,
  // },
  // {
  //   id: 46,
  //   path: `${ecommerce}/sales-channels/:type/:id/settings/shipping-channels/:shipping_channel_id`,
  //   component: WebApiShippingConfigure,
  //   exact: true,
  // },
  {
    id: 47,
    path: `${ecommerce}/customers`,
    component: CustomerList,
    exact: true,
  },
  {
    id: 48,
    path: `${ecommerce}/customers/add`,
    component: CustomerAdd,
    exact: true,
  },
  {
    id: 49,
    path: `${ecommerce}/customers/:customer_id`,
    component: CustomerDetail,
    exact: true,
  },
  {
    id: 50,
    path: `${ecommerce}/customer/groups`,
    component: CustomerGroups,
    exact: true,
  },
];
