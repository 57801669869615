import { defineAbility } from '@casl/ability';
import { CmsPermissionSubject } from './libs/enum/permission-subject';
import {
  ArticlePermission,
  ArticleCategoryPermission,
  BannerPermission,
  ArticleTagPermission,
  ArticleMenuPermission,
  FaqCategoryPermission,
  FaqPermission,
} from './libs/enum/permission';

export default defineAbility((can, _cannot) => {
  /// Article
  can(ArticlePermission.READ, CmsPermissionSubject.ARTICLE);
  can(ArticlePermission.CREATE, CmsPermissionSubject.ARTICLE);
  can(ArticlePermission.UPDATE, CmsPermissionSubject.ARTICLE);
  can(ArticlePermission.DELETE, CmsPermissionSubject.ARTICLE);

  /// Article Category
  can(ArticleCategoryPermission.READ, CmsPermissionSubject.ARTICLE_CATEGORY);
  can(ArticleCategoryPermission.CREATE, CmsPermissionSubject.ARTICLE_CATEGORY);
  can(ArticleCategoryPermission.UPDATE, CmsPermissionSubject.ARTICLE_CATEGORY);
  can(ArticleCategoryPermission.DELETE, CmsPermissionSubject.ARTICLE_CATEGORY);

  /// Banner
  can(BannerPermission.READ, CmsPermissionSubject.BANNER);
  can(BannerPermission.CREATE, CmsPermissionSubject.BANNER);
  can(BannerPermission.UPDATE, CmsPermissionSubject.BANNER);
  can(BannerPermission.DELETE, CmsPermissionSubject.BANNER);

  /// Tag
  can(ArticleTagPermission.READ, CmsPermissionSubject.ARTICLE_TAG);
  can(ArticleTagPermission.CREATE, CmsPermissionSubject.ARTICLE_TAG);
  can(ArticleTagPermission.UPDATE, CmsPermissionSubject.ARTICLE_TAG);
  can(ArticleTagPermission.DELETE, CmsPermissionSubject.ARTICLE_TAG);

  /// MENU
  can(ArticleMenuPermission.READ, CmsPermissionSubject.ARTICLE_MENU);
  can(ArticleMenuPermission.CREATE, CmsPermissionSubject.ARTICLE_MENU);
  can(ArticleMenuPermission.UPDATE, CmsPermissionSubject.ARTICLE_MENU);
  can(ArticleMenuPermission.DELETE, CmsPermissionSubject.ARTICLE_MENU);

  /// FAQ Category
  can(FaqCategoryPermission.READ, CmsPermissionSubject.FAQ_CATEGORY);
  can(FaqCategoryPermission.CREATE, CmsPermissionSubject.FAQ_CATEGORY);
  can(FaqCategoryPermission.UPDATE, CmsPermissionSubject.FAQ_CATEGORY);
  can(FaqCategoryPermission.DELETE, CmsPermissionSubject.FAQ_CATEGORY);

  /// FAQ
  can(FaqPermission.READ, CmsPermissionSubject.FAQ);
  can(FaqPermission.CREATE, CmsPermissionSubject.FAQ);
  can(FaqPermission.UPDATE, CmsPermissionSubject.FAQ);
  can(FaqPermission.DELETE, CmsPermissionSubject.FAQ);
});
