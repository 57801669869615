import { adminService } from './admin';
import { cityService } from './city';

import { branchService } from './branch';

import { imageService } from './image';

import { tenantService } from './tenant';

export const WEB_SERVICES = {
  Admin: adminService,
  City: cityService,
  Branch: branchService,
  Tenant: tenantService,
  Image: imageService,
};
