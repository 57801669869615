import { axiosInstance } from '@config/axios-instance';
import { branches } from '../config';

export const branchService = {
  list({ page = 1, perPage = 10, name = '' }) {
    return axiosInstance.get(`${branches}`, {
      params: {
        page,
        perPage: perPage,
        search: name,
      },
    });
  },
};

export type BranchService = typeof branchService;
