import React from 'react';
import { Modal, Form, Input } from 'antd';
import { WEB_SERVICES } from '@services/index';
import { useNotif } from '@hooks/use-notif';
import { useService } from '@hooks/use-service';
import { AdminService } from '@services/admin';
// import { UserDto } from '@libs/dto/admin';
import { withTransaction } from '@elastic/apm-rum-react';

function ChangePassword({ visible, setVisible }) {
  const adminService = useService<AdminService>(WEB_SERVICES.Admin);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const { addSuccess } = useNotif();

  const handleOk = () => {
    form.validateFields().then(async (values) => {
      setLoading(true);
      try {
        // const uat: UserDto = JSON.parse(localStorage.getItem('uat') || '{}');
        await adminService.query('changePassword', [
          {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          },
        ]);
        addSuccess('Berhasil mengganti Password!');
      } finally {
        form.resetFields();
        setVisible(false);
        setLoading(false);
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Modal title="Change Password" visible={visible} onOk={handleOk} confirmLoading={loading} onCancel={handleCancel}>
        <Form
          form={form}
          onValuesChange={(_changedValues, allValues) => {
            if (allValues.confirmPassword?.length > 0) {
              form.validateFields();
            }
          }}
        >
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[{ required: true, message: 'Please input your Old Password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[{ required: true, message: 'Please input your New Password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              {
                validator(_, value) {
                  if (!value) {
                    return Promise.reject('Please input valid password');
                  }
                  if (value !== form.getFieldValue('newPassword')) {
                    return Promise.reject('Please input valid password');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default withTransaction('ChangePassword', 'component')(ChangePassword);
