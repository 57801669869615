export enum EcommercePermissionSubject {
  REPORT = 'Report',
  LOCATION = 'Location',
  CUSTOMER = 'Customer',
  PRODUCT = 'Product',
  PRODUCT_CATEGORY = 'ProductCategory',
  ORDER = 'Order',
  SALES_CHANNEL = 'SalesChannel',
  VOUCHER = 'Voucher',
  PRODUCT_REVIEW = 'ProductReview',
  PAYMENT_GATEWAY = 'PaymentGateway',
  PAYMENT_CHANNEL = 'PaymentChannel',
  SHIPPING_GATEWAY = 'ShippingGateway',
  SHIPPING_CHANNEL = 'ShippingChannel',
  CUSTOMER_GROUP = 'CustomerGroup',
}
