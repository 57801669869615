import {
  AppstoreFilled,
  SettingFilled,
  ShoppingFilled,
  SmileFilled,
  TagFilled,
  FileTextFilled,
  NotificationFilled,
  ReadFilled,
  // ProjectFilled,
} from '@ant-design/icons';

import { AppMenu } from '@libs/dto';

import { tenant } from './routes.config';

import EcommerceAbility from '@modules/ecommerce/ability';
import TenantAbility from '@modules/tenant/ability';
import CmsAbility from '@modules/cms/ability';

import {
  ProductPermission,
  ReportPermission,
  OrderPermission,
  CustomerPermission,
  // SalesChannelPermission,
  VoucherPermission,
  ProductReviewPermission,
  PaymentGatewayPermission,
  ShippingGatewayPermission,
  LocationPermission,
  ProductCategoryPermission,
  CustomerGroupPermission,
} from '@modules/ecommerce/libs/enum/permission';
import { TenantPermission, UserPermission } from '@modules/tenant/libs/enum/permission';
import {
  ArticleCategoryPermission,
  ArticleMenuPermission,
  ArticlePermission,
  ArticleTagPermission,
  BannerPermission,
  FaqCategoryPermission,
  FaqPermission,
} from '@modules/cms/libs/enum/permission';

import { EcommercePermissionSubject } from '@modules/ecommerce/libs/enum/permission-subject';
import { TenantPermissionSubject } from '@modules/tenant/libs/enum/permission-subject';
import { CmsPermissionSubject } from '@modules/cms/libs/enum/permission-subject';

// import { cmsMenus } from './module/cms/menus';
// import { roomMenus } from './module/room/menus';
// import { ecommerceMenus } from './module/ecommerce/menus';
// import { foodMenus } from './module/food/menus';
// import { souvenirMenus } from './module/souvenir/menus';

export const tenantMenus: AppMenu = [
  {
    name: 'Dashboard',
    title: 'Dashboard',
    linkTo: `${tenant}/ecommerce/dashboard`,
    Icon: AppstoreFilled,
    key: '1',
    permission: [
      {
        ability: EcommerceAbility,
        action: ReportPermission.READ,
        subject: EcommercePermissionSubject.REPORT,
      },
      {
        ability: EcommerceAbility,
        action: CustomerPermission.READ,
        subject: EcommercePermissionSubject.CUSTOMER,
      },
    ],
  },
  {
    name: 'Orders',
    title: 'Orders',
    linkTo: `${tenant}/ecommerce/orders`,
    Icon: ShoppingFilled,
    key: '2',
    permission: [
      {
        ability: EcommerceAbility,
        action: OrderPermission.READ,
        subject: EcommercePermissionSubject.ORDER,
      },
    ],
  },
  {
    name: 'Products',
    title: 'Products',
    linkTo: `${tenant}/ecommerce/products`,
    Icon: TagFilled,
    key: '3',
    isParent: true,
    permission: [
      {
        ability: EcommerceAbility,
        action: ProductPermission.READ,
        subject: EcommercePermissionSubject.PRODUCT,
      },
    ],
    subMenu: [
      {
        title: 'All Products',
        linkTo: `${tenant}/ecommerce/products`,
        subKey: '3.1',
        permission: [
          {
            ability: EcommerceAbility,
            action: ProductPermission.READ,
            subject: EcommercePermissionSubject.PRODUCT,
          },
          {
            ability: EcommerceAbility,
            action: ProductCategoryPermission.READ,
            subject: EcommercePermissionSubject.PRODUCT_CATEGORY,
          },
        ],
      },
      {
        title: 'Inventory',
        linkTo: `${tenant}/ecommerce/inventory`,
        subKey: '3.2',
        permission: [
          {
            ability: EcommerceAbility,
            action: ProductPermission.READ,
            subject: EcommercePermissionSubject.PRODUCT,
          },
        ],
      },
      {
        title: 'Category',
        linkTo: `${tenant}/ecommerce/products/categories`,
        subKey: '3.3',
        permission: [
          {
            ability: EcommerceAbility,
            action: ProductCategoryPermission.READ,
            subject: EcommercePermissionSubject.PRODUCT_CATEGORY,
          },
        ],
      },
    ],
  },
  {
    name: 'Customers',
    title: 'Customers',
    linkTo: `${tenant}/ecommerce/customers`,
    Icon: SmileFilled,
    key: '4',
    isParent: true,
    permission: [
      {
        ability: EcommerceAbility,
        action: CustomerPermission.READ,
        subject: EcommercePermissionSubject.CUSTOMER,
      },
      {
        ability: EcommerceAbility,
        action: CustomerGroupPermission.READ,
        subject: EcommercePermissionSubject.CUSTOMER_GROUP,
      },
    ],
    subMenu: [
      {
        title: 'All Customers',
        linkTo: `${tenant}/ecommerce/customers`,
        subKey: '4.1',
        permission: [
          {
            ability: EcommerceAbility,
            action: CustomerPermission.READ,
            subject: EcommercePermissionSubject.CUSTOMER,
          },
        ],
      },
      {
        title: 'Customer Groups',
        linkTo: `${tenant}/ecommerce/customer/groups`,
        subKey: '4.2',
        permission: [
          {
            ability: EcommerceAbility,
            action: CustomerGroupPermission.READ,
            subject: EcommercePermissionSubject.CUSTOMER_GROUP,
          },
        ],
      },
    ],
  },
  {
    name: 'Reviews',
    title: 'Reviews',
    linkTo: `${tenant}/ecommerce/reviews`,
    Icon: ReadFilled,
    key: '5',
    permission: [
      {
        ability: EcommerceAbility,
        action: ProductReviewPermission.READ,
        subject: EcommercePermissionSubject.PRODUCT_REVIEW,
      },
    ],
  },
  {
    name: 'CMS',
    title: 'CMS',
    linkTo: `${tenant}/cms`,
    Icon: FileTextFilled,
    key: '6',
    isParent: true,
    permission: [
      {
        ability: CmsAbility,
        action: ArticlePermission.READ,
        subject: CmsPermissionSubject.ARTICLE,
      },
      {
        ability: CmsAbility,
        action: ArticleCategoryPermission.READ,
        subject: CmsPermissionSubject.ARTICLE_CATEGORY,
      },
      {
        ability: CmsAbility,
        action: ArticleMenuPermission.READ,
        subject: CmsPermissionSubject.ARTICLE_MENU,
      },
      {
        ability: CmsAbility,
        action: ArticleTagPermission.READ,
        subject: CmsPermissionSubject.ARTICLE_TAG,
      },
      {
        ability: CmsAbility,
        action: BannerPermission.READ,
        subject: CmsPermissionSubject.BANNER,
      },
      {
        ability: CmsAbility,
        action: FaqCategoryPermission.READ,
        subject: CmsPermissionSubject.FAQ_CATEGORY,
      },
      {
        ability: CmsAbility,
        action: FaqPermission.READ,
        subject: CmsPermissionSubject.FAQ,
      },
    ],
    subMenu: [
      {
        title: 'Articles',
        linkTo: `${tenant}/cms/articles`,
        subKey: '6.1',
        permission: [
          {
            ability: CmsAbility,
            action: ArticlePermission.READ,
            subject: CmsPermissionSubject.ARTICLE,
          },
        ],
      },
      {
        title: 'Categories',
        linkTo: `${tenant}/cms/categories`,
        subKey: '6.2',
        permission: [
          {
            ability: CmsAbility,
            action: ArticleCategoryPermission.READ,
            subject: CmsPermissionSubject.ARTICLE_CATEGORY,
          },
        ],
      },
      {
        title: 'Banners',
        linkTo: `${tenant}/cms/banners`,
        subKey: '6.3',
        permission: [
          {
            ability: CmsAbility,
            action: BannerPermission.READ,
            subject: CmsPermissionSubject.BANNER,
          },
        ],
      },
      {
        title: 'Tags',
        linkTo: `${tenant}/cms/tags`,
        subKey: '6.4',
        permission: [
          {
            ability: CmsAbility,
            action: ArticleTagPermission.READ,
            subject: CmsPermissionSubject.ARTICLE_TAG,
          },
        ],
      },
      {
        title: 'Menus',
        linkTo: `${tenant}/cms/menus`,
        subKey: '6.5',
        permission: [
          {
            ability: CmsAbility,
            action: ArticleMenuPermission.READ,
            subject: CmsPermissionSubject.ARTICLE_MENU,
          },
        ],
      },
      {
        title: 'FAQ',
        linkTo: `${tenant}/cms/faq`,
        subKey: '6.6',
        permission: [
          {
            ability: CmsAbility,
            action: FaqPermission.READ,
            subject: CmsPermissionSubject.FAQ,
          },
        ],
      },
      {
        title: 'FAQ Categories',
        linkTo: `${tenant}/cms/faq/categories`,
        subKey: '6.7',
        permission: [
          {
            ability: CmsAbility,
            action: FaqCategoryPermission.READ,
            subject: CmsPermissionSubject.FAQ_CATEGORY,
          },
        ],
      },
    ],
  },
  {
    name: 'Marketing',
    title: 'Marketing',
    linkTo: `${tenant}/ecommerce/marketing`,
    Icon: NotificationFilled,
    key: '7',
    isParent: true,
    permission: [
      {
        ability: EcommerceAbility,
        action: VoucherPermission.READ,
        subject: EcommercePermissionSubject.VOUCHER,
      },
    ],
    subMenu: [
      {
        title: 'Vouchers',
        linkTo: `${tenant}/ecommerce/marketing/vouchers`,
        subKey: '7.1',
        permission: [
          {
            ability: EcommerceAbility,
            action: VoucherPermission.READ,
            subject: EcommercePermissionSubject.VOUCHER,
          },
        ],
      },
    ],
  },
  // {
  //   name: 'Sales Channels',
  //   title: 'Sales Channels',
  //   linkTo: `${tenant}/ecommerce/sales-channels`,
  //   Icon: ProjectFilled,
  //   key: '8',
  //   isParent: true,
  //   isPinned: true,
  //   permission: [
  //     {
  //       ability: EcommerceAbility,
  //       action: SalesChannelPermission.READ,
  //       subject: EcommercePermissionSubject.SALES_CHANNEL,
  //     },
  //   ],
  //   subMenu: [
  //     {
  //       title: 'All Sales Channel',
  //       linkTo: `${tenant}/ecommerce/sales-channels`,
  //       subKey: '8.1',
  //     },
  //   ],
  // },

  // ...roomMenus,
  // ...ecommerceMenus,
  // ...foodMenus,
  // ...souvenirMenus,
  // ...cmsMenus,
  {
    name: 'Setting',
    title: 'Settings',
    linkTo: `${tenant}/settings`,
    Icon: SettingFilled,
    key: '9',
    permission: [
      {
        ability: TenantAbility,
        action: TenantPermission.READ,
        subject: TenantPermissionSubject.TENANT,
      },
      {
        ability: TenantAbility,
        action: UserPermission.READ,
        subject: TenantPermissionSubject.USER,
      },
      {
        ability: EcommerceAbility,
        action: PaymentGatewayPermission.READ,
        subject: EcommercePermissionSubject.PAYMENT_GATEWAY,
      },
      {
        ability: EcommerceAbility,
        action: ShippingGatewayPermission.READ,
        subject: EcommercePermissionSubject.SHIPPING_GATEWAY,
      },
      {
        ability: EcommerceAbility,
        action: LocationPermission.READ,
        subject: EcommercePermissionSubject.LOCATION,
      },
    ],
  },
];
