import { AnyMongoAbility } from '@casl/ability';
import { UserDto } from '@libs/dto';

export const permission = (ability: AnyMongoAbility, action: string, subject: string) => {
  const identity: UserDto = JSON.parse(localStorage.getItem('uat'));
  return ability.can(
    identity?.permissions?.find((item) => item === action),
    subject
  );
};
