import React from 'react';
import { AppRoutes } from '@libs/dto';

import {
  CmsRoutes,
  // RoomRoutes,
  // FoodRoutes,
  // SouvenirRoutes
  EcommerceRoutes,
  NotificationRoutes,
} from './module/index';

import { tenant } from './routes.config';

// const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Settings = React.lazy(() => import('./pages/Settings'));
const AddAdmin = React.lazy(() => import('./pages/Settings/Admins/Add'));
const EditAdmin = React.lazy(() => import('./pages/Settings/Admins/Edit'));
const AddTenant = React.lazy(() => import('./pages/Add'));

export const routes: AppRoutes = [
  // {
  //   id: 1,
  //   path: `${tenant}/dashboard`,
  //   component: Dashboard,
  //   exact: true,
  // },
  ...EcommerceRoutes,
  ...CmsRoutes,
  ...NotificationRoutes,
  {
    id: 2,
    path: `${tenant}/settings`,
    component: Settings,
    // render: (props) => <Settings />,
    exact: true,
  },
  {
    id: 8,
    path: `${tenant}/settings/admins/add`,
    component: AddAdmin,
    exact: true,
  },
  {
    id: 9,
    path: `${tenant}/settings/admins/:id/edit`,
    component: EditAdmin,
    exact: true,
  },
  {
    id: 10,
    path: `${tenant}/add`,
    component: AddTenant,
    // render: (props) => <Settings />,
    exact: true,
  },
  // ...RoomRoutes,
  // ...FoodRoutes,
  // ...SouvenirRoutes,
];
