import React from 'react';
import { AppRoutes } from '@libs/dto';
import { notification } from './routes.config';
const AddNotification = React.lazy(() => import('@modules/notification/pages/Add'));
const ConfigureNotificationChannel = React.lazy(() => import('@modules/notification/pages/Channel/Configure'));
const ChannelDetail = React.lazy(() => import('@modules/notification/pages/Detail'));
const NotificationLogsList = React.lazy(() => import('@modules/notification/pages/Logs/List'));
const NotificationLogsDetail = React.lazy(() => import('@modules/notification/pages/Logs/Detail'));
const NotificationTemplate = React.lazy(() => import('@modules/notification/pages/Template'));

export const routes: AppRoutes = [
  {
    id: 1,
    path: `${notification}/add`,
    component: AddNotification,
    exact: true,
  },
  {
    id: 2,
    path: `${notification}/notification-channels/:code`,
    component: ConfigureNotificationChannel,
    exact: true,
  },
  {
    id: 3,
    path: `${notification}/channel/:channelId/:channel`,
    component: ChannelDetail,
    exact: true,
  },
  {
    id: 4,
    path: `${notification}/notifications/logs`,
    component: NotificationLogsList,
    exact: true,
  },
  {
    id: 5,
    path: `${notification}/notifications/logs/:logId`,
    component: NotificationLogsDetail,
    exact: true,
  },
  {
    id: 6,
    path: `${notification}/channel/:channelId/:channel/event/:eventId`,
    component: NotificationTemplate,
    exact: true,
  },
];
