import React from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { AdminSignInDto } from '@libs/dto/admin';
import { useAuth } from '@context/auth-context';
// import LogoDHM from '@assets/DHM_logo.png';
import './Login.scss';
import { useHistory } from 'react-router-dom';
import ContentHelmet from '@components/ContentHelmet';
import { registerHostname } from '@config/allowed-hostname';

const Login = () => {
  const history = useHistory();
  const { signIn, isSubmitted, isError } = useAuth();

  const onFinish = (values: AdminSignInDto) => {
    signIn(values);
  };

  const [filterForm] = Form.useForm();

  const { Title, Text } = Typography;

  React.useEffect(() => {
    if (isError) {
      filterForm.setFields([
        {
          name: 'email',
          errors: ['Invalid Email'],
        },
        {
          name: 'password',
          errors: ['Invalid Password'],
        },
      ]);
    }
  }, [isError, filterForm]);

  return (
    <ContentHelmet title="Login">
      <div className="form">
        <div>
          <div className="logo">
            {/* <img src={LogoDHM} alt="Logo DHM" /> */}
            <Title level={2} className="title-text">
              {process.env.REACT_APP_TITLE || 'Warung'}
            </Title>
          </div>

          <Form
            form={filterForm}
            size="middle"
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              hasFeedback
              name="email"
              rules={[{ required: true, message: 'Please input your Email!' }, { type: 'email' }]}
            >
              <Input
                style={{ minWidth: 300 }}
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
              <Input.Password
                style={{ minWidth: 300 }}
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
              />
            </Form.Item>

            <div style={{ marginBottom: 20 }}>
              <Text style={{ color: 'rgb(125 125 125)', fontSize: 14 }}>
                <span onClick={() => history.push('/reset-password')} style={{ color: '#0050b3', cursor: 'pointer' }}>
                  Forgot password?{' '}
                </span>
              </Text>
            </div>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" loading={isSubmitted}>
                Login
              </Button>
            </Form.Item>
          </Form>
          <Button onClick={() => history.push('/login-phone')} className="login-form-button">
            Login with Phone
          </Button>
        </div>
        {registerHostname.findIndex((item) => item === window.location.hostname) !== -1 && (
          <div style={{ marginTop: 70 }}>
            <Text style={{ color: 'rgb(125 125 125)', fontSize: 14 }}>
              Don't have any account yet?{' '}
              <span onClick={() => history.push('/register')} style={{ color: '#0050b3', cursor: 'pointer' }}>
                Sign up here
              </span>
            </Text>
          </div>
        )}
      </div>
    </ContentHelmet>
  );
};

export default Login;
