import React from 'react';
import Sidebar from '@components/Sidebar/Sidebar';
import { Layout, Spin, Row } from 'antd';
import { Switch, Route, withRouter } from 'react-router-dom';
import { routes } from './routes';
import { AppMenu } from '@libs/dto';
import { branchMenus } from './menus';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import { useMenus } from '@hooks/use-menus';
import * as Sentry from '@sentry/react';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

const Spinner = () => (
  <Layout style={{ placeContent: 'center', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  </Layout>
);

function Tenant() {
  const sidebarMenus: AppMenu = useMenus(branchMenus);
  const baseSiteName = process.env.REACT_APP_BASE_SITE_NAME || 'Warung';

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Helmet>
          <title>{baseSiteName}</title>
        </Helmet>
        <Sidebar menus={sidebarMenus} />
        <Layout className="site-layout">
          <Header />
          <div style={{ marginLeft: 270, marginTop: 65 }}>
            <React.Suspense fallback={<Spinner />}>
              <Switch>
                {routes.map((route) => (
                  <SentryRoute {...route} key={route.id} />
                ))}
              </Switch>
            </React.Suspense>
          </div>
        </Layout>
      </Layout>
    </>
  );
}

export default withRouter(Tenant);
