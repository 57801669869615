import { axiosInstance } from '@config/axios-instance';
import {
  AdminSignInDto,
  AdminAndTokenDto,
  AdminUpdateProfile,
  AddAdminBranchDto,
  ChangePasswordDto,
  BranchPlacesAddDto,
  AdminSignInPhoneDto,
  RequestResetPasswordDto,
  ResetPasswordDto,
  AdminSignInWhatsAppDto,
  AdminSignInWhatsAppOtpDto,
} from '@libs/dto/admin';

const url = '/admin/settings/admins';
const urlAuth = '/admin';

export const adminService = {
  list({ page = 1, perPage = 10 }) {
    return axiosInstance.get(`${url}`, {
      params: { page, perPage },
    });
  },
  listByBranch(id: string, page = 1, perPage = 10) {
    return axiosInstance.get(`${url}/${id}/by-branch`, {
      params: { page, perPage },
    });
  },
  get(id: string) {
    return axiosInstance.get(`${url}/${id}`);
  },
  signIn(credential: AdminSignInDto): Promise<AdminAndTokenDto> {
    return axiosInstance.post(`${urlAuth}/tenant/auth/login-with-email`, credential);
  },
  signInPhoneFirebase(credential: AdminSignInPhoneDto): Promise<AdminAndTokenDto> {
    return axiosInstance.post(`${urlAuth}/tenant/auth/login-with-firebase-phone`, credential);
  },
  requestOtpWhatsApp(credential: AdminSignInWhatsAppDto) {
    return axiosInstance.post(`${urlAuth}/tenant/auth/send-otp`, credential);
  },
  verifyOtpWhatsApp(credential: AdminSignInWhatsAppOtpDto): Promise<AdminAndTokenDto> {
    return axiosInstance.post(`${urlAuth}/tenant/auth/verify-otp`, credential);
  },
  create(data: AddAdminBranchDto) {
    return axiosInstance.post(`${urlAuth}/auth/register`, data);
  },
  update(id: string, data: AdminUpdateProfile) {
    return axiosInstance.put(`${url}/${id}`, data);
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
  changePassword(data: ChangePasswordDto) {
    return axiosInstance.put(`${urlAuth}/tenant/auth/change-password`, data);
  },
  getPlaces(branch_id: number, page: number, perPage: number) {
    return axiosInstance.get(`${urlAuth}/hotels/${branch_id}/settings/places`, {
      params: { page, perPage },
    });
  },
  createPlaces(branch_id: number, data: BranchPlacesAddDto) {
    return axiosInstance.post(`${urlAuth}/hotels/${branch_id}/settings/places`, data);
  },
  updatePlaces(branch_id: number, id: string, data: BranchPlacesAddDto) {
    return axiosInstance.put(`${urlAuth}/hotels/${branch_id}/settings/places/${id}`, data);
  },
  deletePlaces(branch_id: number, id: string) {
    return axiosInstance.delete(`${urlAuth}/hotels/${branch_id}/settings/places/${id}`);
  },
  getTenantProfile() {
    return axiosInstance.get(`${urlAuth}/tenant/profile`);
  },
  requestResetPassword(body: RequestResetPasswordDto) {
    return axiosInstance.post(`${urlAuth}/tenant/auth/request-reset-password`, body);
  },
  resetPassword(body: ResetPasswordDto, token: string, tenant: string) {
    return axiosInstance.post(`${urlAuth}/tenant/auth/reset-password`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-tenant-id': tenant,
      },
    });
  },
};

export type AdminService = typeof adminService;
