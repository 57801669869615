import React, { useState } from 'react';
import Sidebar from '@components/Sidebar/Sidebar';
import { Layout, Spin, Row } from 'antd';
import { Switch, Route, withRouter, useLocation } from 'react-router-dom';
import { routes } from './routes';
import { AppMenu } from '@libs/dto';
import { tenantMenus } from './menus';
// import Header from '@components/Header';
import { Helmet } from 'react-helmet';
// import axios from 'axios';
import * as Sentry from '@sentry/react';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

const Spinner = () => (
  <Layout style={{ placeContent: 'center', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  </Layout>
);

function Tenant() {
  // const sidebarMenus: AppMenu = tenantMenus;
  const baseSiteName = process.env.REACT_APP_BASE_SITE_NAME || 'Warung';
  const location = useLocation();

  const manageSalesChannelWebApi =
    location.pathname.includes('/tenant/ecommerce/sales-channels/web-api') &&
    location.pathname !== '/tenant/ecommerce/sales-channels/web-api/add';

  const manageSalesChannelMobileApi =
    location.pathname.includes('/tenant/ecommerce/sales-channels/mobile-api') &&
    location.pathname !== '/tenant/ecommerce/sales-channels/mobile-api/add';

  const isSidebarVisible = !manageSalesChannelWebApi && !manageSalesChannelMobileApi;
  // eslint-disable-next-line
  const [newMenus, setNewMenus] = useState<AppMenu>([]);

  // const newMenus = usePinned({ menus: sidebarMenus, pinned: listPinnedSales });

  // const getPinnedSales = async () => {
  //   const getToken = localStorage.getItem('token');
  //   const getTenantId = localStorage.getItem('tenantId');

  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}admin/tenant/ecommerce/sales-channels?page=1&perPage=1000&isPinned=true`, {
  //       headers: {
  //         Authorization: `Bearer ${getToken}`,
  //         'x-tenant-id': getTenantId,
  //       },
  //     })
  //     .then((pinnedSales) => {
  //       if (pinnedSales?.data?.data?.length > 0) {
  //         const newChild = pinnedSales?.data?.data?.map((data, index) => {
  //           return {
  //             title: data.name,
  //             linkTo: `/tenant/ecommerce/sales-channels/${data.channel}/${data.id}/settings?tab=general`,
  //             subKey: index.toString(),
  //           };
  //         });
  //         let newData = tenantMenus;
  //         const indexPinned = newData.indexOf(newData.find((item) => item.isPinned === true));
  //         newData[indexPinned].subMenu = [...newData[indexPinned].subMenu, ...newChild];
  //         setNewMenus(newData);
  //       } else {
  //         setNewMenus(sidebarMenus);
  //       }
  //     })
  //     .catch((_error) => {
  //       setNewMenus(sidebarMenus);
  //     });
  // };

  // useEffect(() => {
  //   getPinnedSales();
  //   // eslint-disable-next-line
  // }, []);

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Helmet>
          <title>{baseSiteName}</title>
        </Helmet>

        {isSidebarVisible && <Sidebar menus={newMenus.length > 0 ? newMenus : tenantMenus} />}

        <Layout className="site-layout">
          {/* <Header /> */}
          <div style={{ marginLeft: isSidebarVisible ? 270 : 0 }}>
            <React.Suspense fallback={<Spinner />}>
              <Switch>
                {routes.map((route) => (
                  <SentryRoute {...route} key={route.id} />
                ))}
              </Switch>
            </React.Suspense>
          </div>
        </Layout>
      </Layout>
    </>
  );
}

export default withRouter(Tenant);
