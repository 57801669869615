export enum ReportPermission {
  READ = 'READ_REPORT',
}

export enum LocationPermission {
  CREATE = 'CREATE_ECOMMERCE_LOCATION',
  READ = 'READ_ECOMMERCE_LOCATION',
  UPDATE = 'UPDATE_ECOMMERCE_LOCATION',
  DELETE = 'DELETE_ECOMMERCE_LOCATION',
}

export enum CustomerPermission {
  CREATE = 'CREATE_ECOMMERCE_CUSTOMER',
  READ = 'READ_ECOMMERCE_CUSTOMER',
  UPDATE = 'UPDATE_ECOMMERCE_CUSTOMER',
  DELETE = 'DELETE_ECOMMERCE_CUSTOMER',
}

export enum ProductPermission {
  CREATE = 'CREATE_ECOMMERCE_PRODUCT',
  READ = 'READ_ECOMMERCE_PRODUCT',
  UPDATE = 'UPDATE_ECOMMERCE_PRODUCT',
  DELETE = 'DELETE_ECOMMERCE_PRODUCT',
}

export enum ProductCategoryPermission {
  CREATE = 'CREATE_ECOMMERCE_PRODUCT_CATEGORY',
  READ = 'READ_ECOMMERCE_PRODUCT_CATEGORY',
  UPDATE = 'UPDATE_ECOMMERCE_PRODUCT_CATEGORY',
  DELETE = 'DELETE_ECOMMERCE_PRODUCT_CATEGORY',
}

export enum OrderPermission {
  CREATE = 'CREATE_ECOMMERCE_ORDER',
  READ = 'READ_ECOMMERCE_ORDER',
  UPDATE = 'UPDATE_ECOMMERCE_ORDER',
}

export enum SalesChannelPermission {
  CREATE = 'CREATE_ECOMMERCE_SALES_CHANNEL',
  READ = 'READ_ECOMMERCE_SALES_CHANNEL',
  UPDATE = 'UPDATE_ECOMMERCE_SALES_CHANNEL',
  DELETE = 'DELETE_ECOMMERCE_SALES_CHANNEL',
}

export enum VoucherPermission {
  CREATE = 'CREATE_ECOMMERCE_VOUCHER',
  READ = 'READ_ECOMMERCE_VOUCHER',
  UPDATE = 'UPDATE_ECOMMERCE_VOUCHER',
  DELETE = 'DELETE_ECOMMERCE_VOUCHER',
}

export enum ProductReviewPermission {
  CREATE = 'CREATE_ECOMMERCE_PRODUCT_REVIEW',
  READ = 'READ_ECOMMERCE_PRODUCT_REVIEW',
  UPDATE = 'UPDATE_ECOMMERCE_PRODUCT_REVIEW',
  DELETE = 'DELETE_ECOMMERCE_PRODUCT_REVIEW',
}

export enum PaymentGatewayPermission {
  CREATE = 'CREATE_ECOMMERCE_PAYMENT_GATEWAY',
  READ = 'READ_ECOMMERCE_PAYMENT_GATEWAY',
  UPDATE = 'UPDATE_ECOMMERCE_PAYMENT_GATEWAY',
  DELETE = 'DELETE_ECOMMERCE_PAYMENT_GATEWAY',
}

export enum PaymentChannelPermission {
  CREATE = 'CREATE_ECOMMERCE_PAYMENT_CHANNEL',
  READ = 'READ_ECOMMERCE_PAYMENT_CHANNEL',
  UPDATE = 'UPDATE_ECOMMERCE_PAYMENT_CHANNEL',
  DELETE = 'DELETE_ECOMMERCE_PAYMENT_CHANNEL',
}

export enum ShippingGatewayPermission {
  CREATE = 'CREATE_ECOMMERCE_SHIPPING_GATEWAY',
  READ = 'READ_ECOMMERCE_SHIPPING_GATEWAY',
  UPDATE = 'UPDATE_ECOMMERCE_SHIPPING_GATEWAY',
  DELETE = 'DELETE_ECOMMERCE_SHIPPING_GATEWAY',
}

export enum ShippingChannelPermission {
  CREATE = 'CREATE_ECOMMERCE_SHIPPING_CHANNEL',
  READ = 'READ_ECOMMERCE_SHIPPING_CHANNEL',
  UPDATE = 'UPDATE_ECOMMERCE_SHIPPING_CHANNEL',
  DELETE = 'DELETE_ECOMMERCE_SHIPPING_CHANNEL',
}

export enum CustomerGroupPermission {
  CREATE = 'CREATE_ECOMMERCE_CUSTOMER_GROUP',
  READ = 'READ_ECOMMERCE_CUSTOMER_GROUP',
  UPDATE = 'UPDATE_ECOMMERCE_CUSTOMER_GROUP',
  DELETE = 'DELETE_ECOMMERCE_CUSTOMER_GROUP',
}
