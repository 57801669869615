export enum TenantPermission {
  CREATE = 'CREATE_TENANT',
  READ = 'READ_TENANT',
  UPDATE = 'UPDATE_TENANT',
  DELETE = 'DELETE_TENANT',
}

export enum UserPermission {
  CREATE = 'CREATE_USER',
  READ = 'READ_USER',
  UPDATE = 'UPDATE_USER',
  DELETE = 'DELETE_USER',
}
