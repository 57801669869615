import React from 'react';
import { Menu, Layout } from 'antd';
import { useAuth } from '@context/auth-context';
import './Header.scss';
import ChangePassword from './ChangePassword';
import { Avatar } from 'antd';
import { DownOutlined, UserOutlined, HomeOutlined } from '@ant-design/icons';
// import BackIcon from '@assets/backIcon.svg';
import { useApp } from '@context/app-context';
import { Breadcrumb } from 'antd';
import { useLocation } from 'react-router-dom';
// import { breadCrumbs } from './breadcrumb';
import { withTransaction } from '@elastic/apm-rum-react';

const { SubMenu, Item } = Menu;

type HeaderProps = {
  breadcrumbItems?: Array<{ title: string; href: string }>;
};

function Header({ breadcrumbItems = [{ title: '', href: '/tenant/ecommerce/dashboard' }] }: HeaderProps) {
  const { signOut, identity } = useAuth();
  const [visible, setVisible] = React.useState(false);
  const { selectedBranchId } = useApp();
  const location = useLocation();

  const manageSalesChannelWebApi =
    location.pathname.includes('/tenant/ecommerce/sales-channels/web-api') &&
    location.pathname !== '/tenant/ecommerce/sales-channels/web-api/add';

  const manageSalesChannelMobileApi =
    location.pathname.includes('/tenant/ecommerce/sales-channels/mobile-api') &&
    location.pathname !== '/tenant/ecommerce/sales-channels/mobile-api/add';

  const onErrorPage =
    ['/forbidden', '/bad-gateway', '/not-found', '/server-error'].findIndex(
      (path) => path === window.location.pathname
    ) !== -1;

  const isFullWidth = manageSalesChannelWebApi || manageSalesChannelMobileApi || onErrorPage;

  const handleClickMenu = (e) => {
    // eslint-disable-next-line no-unused-expressions
    e.key === 'SignOut' && signOut();

    // eslint-disable-next-line no-unused-expressions
    e.key === 'ChangePassword' && setVisible(true);
  };

  return (
    <Layout.Header
      className={selectedBranchId ? 'site-header-background-branch' : 'site-header-background'}
      style={{
        width: isFullWidth ? '100%' : 'calc(100% - 270px)',
        marginLeft: onErrorPage ? '0px' : '-10px',
        marginTop: onErrorPage ? '0px' : '-10px',
      }}
    >
      {/* {window.location.pathname !== '/tenant/dashboard' && (
        <div className="branch-name">
          <img alt="back" src={BackIcon} onClick={() => history.goBack()} style={{ width: 25, cursor: 'pointer' }} />
        </div>
      )} */}
      <div className="breadcrumb">
        <Breadcrumb>
          {breadcrumbItems.map((item, itemIndex) => {
            if (itemIndex === 0) {
              return (
                <Breadcrumb.Item href="/">
                  <HomeOutlined />
                </Breadcrumb.Item>
              );
            } else {
              return (
                <Breadcrumb.Item href={item.href}>
                  <span>{item.title}</span>
                </Breadcrumb.Item>
              );
            }
          })}
        </Breadcrumb>
      </div>

      <div className="right-container">
        {/* <Badge count={3}>
          <NotifIcon />
        </Badge> */}
        <Menu
          key="user"
          triggerSubMenuAction={'hover' || 'click'}
          mode="horizontal"
          onClick={handleClickMenu}
          style={{ marginLeft: 24 }}
        >
          <SubMenu
            title={
              <span className="right-sub-menu-title">
                <Avatar icon={<UserOutlined style={{ color: 'white !important' }} />} />
                <span className="user-name">
                  {identity.name || 'Guest'} <DownOutlined />
                </span>
              </span>
            }
          >
            <Item className="header" key="ChangePassword">
              <p>Change Password</p>
            </Item>
            <Item className="header" key="SignOut">
              <p>Sign out</p>
            </Item>
          </SubMenu>
        </Menu>
      </div>
      <ChangePassword visible={visible} setVisible={setVisible} />
    </Layout.Header>
  );
}

export default withTransaction('Header', 'component')(Header);
